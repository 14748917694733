
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.radio-group-view {
  display: flex;
  flex-direction: column;
}
